<template>
    <div class="app-container">
        <div class="filter-container">
            <el-button class="filter-item" type="primary" size="small" @click="$router.go(-1)">返回</el-button>
        </div>

        <div class="filter-container">
            <el-select class="filter-item" filterable v-model="listQuery.canteen_id" size="small" placeholder="请选择食堂" style="width: 140px" clearable>
                <el-option v-for="item in canteens" :key="item.id" :label="item.canteen_name" :value="item.id">
                </el-option>
            </el-select>

            <el-select class="filter-item" filterable v-model="listQuery.shop_id" size="small" placeholder="请选择店铺" style="width: 140px" clearable>
                <el-option v-for="item in shops" :key="item.id" :label="item.shop_name" :value="item.id">
                </el-option>
            </el-select>

            <el-select class="filter-item" filterable v-model="listQuery.category_id" size="small" placeholder="请选择分类" style="width: 140px" clearable>
                <el-option v-for="item in categories" :key="item.id" :label="item.category_name" :value="item.id">
                </el-option>
            </el-select>

            <el-input v-model="listQuery.keyword" size="small" placeholder="请输入商品名称" style="width: 200px;" class="filter-item" clearable/>

            <el-button class="filter-item" type="primary" size="small" @click="handleFilter">搜索</el-button>
        </div>

        <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
            <el-table-column align="center" label="ID" width="80">
                <template slot-scope="scope">
                    {{ scope.row.id }}
                </template>
            </el-table-column>

            <el-table-column label="学校" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.school?scope.row.school.school_name:'' }}
                </template>
            </el-table-column>

            <el-table-column label="食堂" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.shop.canteen?scope.row.shop.canteen.canteen_name:'' }}
                </template>
            </el-table-column>

            <el-table-column label="店铺" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.shop.shop_name }}
                </template>
            </el-table-column>

            <el-table-column label="分类" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.category.category_name }}
                </template>
            </el-table-column>

            <el-table-column label="商品名称" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.goods_name }}
                </template>
            </el-table-column>

            <el-table-column label="封面" min-width="110" align="center">
                <template slot-scope="scope">
                    <img :src="scope.row.goods_thumb" class="user-avatar">
                </template>
            </el-table-column>

            <el-table-column label="库存" min-width="80" align="center">
                <template slot-scope="scope">
                    {{ scope.row.goods_number }}
                </template>
            </el-table-column>

            <el-table-column label="操作" fixed="right" align="center" width="200" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" @click="handleInfo(scope.row)">商品详情</el-button>

                    <el-button type="warning" size="mini" @click="handleCreate(scope.row)">设为特价</el-button>
                </template>
            </el-table-column>
        </el-table>

        <div class="pagination-container">
            <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>

        <el-dialog :visible.sync="dialogVisible">
            <el-form ref="formInfo" :model="formInfo" label-position="left" label-width="90px">
                <el-form-item label="商品名称">
                    <el-input v-model="formInfo.goods_name" placeholder="请输入商品名称" />
                </el-form-item>
                <el-form-item label="商品描述">
                    <el-input v-model="formInfo.goods_description" placeholder="请输入商品描述" />
                </el-form-item>
                <el-form-item label="封面">
                    <upload-one v-model="formInfo.goods_thumb"></upload-one>
                    <div style="font-size:12px;color:red;">*推荐尺寸：160*160</div>
                </el-form-item>
                <el-row>
                    <el-col :span="10">
                        <el-form-item label="商品价格">
                            <el-input v-model="formInfo.sell_price" type="number" min="0" placeholder="请输入商品价格" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="2">
                        <el-form-item label="包装费">
                            <el-input v-model="formInfo.pack_fee" type="number" min="0" placeholder="请输入包装费" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="10">
                        <el-form-item label="库存">
                            <el-input v-model="formInfo.goods_number" type="number" min="0" placeholder="请输入库存" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="2">
                        <el-form-item label="权重">
                            <el-input v-model="formInfo.weight" type="number" min="0" placeholder="请输入库存" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="规格">
                    <el-table size="mini" :data="formInfo.specs" border fit>
                        <el-table-column label="名称" align="center" min-width="120">
                            <template slot-scope="scope">
                                <el-input size="mini" v-model="scope.row.spec_name" />
                            </template>
                        </el-table-column>
                        <el-table-column label="价格(元)" align="center" min-width="120">
                            <template slot-scope="scope">
                                <el-input size="mini" v-model="scope.row.spec_price" type="number" min="0" />
                            </template>
                        </el-table-column>
                        <el-table-column label="库存" align="center" min-width="120">
                            <template slot-scope="scope">
                                <el-input size="mini" v-model="scope.row.spec_num" type="number" min="0" />
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
                <el-form-item label="属性">
                    <el-table size="mini" :data="formInfo.attrs" border fit>
                        <el-table-column label="名称" align="center" min-width="120">
                            <template slot-scope="scope">
                                <el-input size="mini" v-model="scope.row.attr_name" />
                            </template>
                        </el-table-column>
                        <el-table-column label="值" align="center" min-width="240">
                            <template slot-scope="scope">
                                <div :key="index" v-for="(item,index) in scope.row.attr_val" style="margin-bottom: 10px;">
                                    <el-input style="display: inline-block;width: 150px;margin-right: 10px;" size="mini" v-model="item.name" placeholder="属性值名称" />
                                    <el-input style="display: inline-block;width: 150px;margin-right: 10px;" size="mini" v-model="item.price" placeholder="属性值价格" @input="item.price=item.price.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/\.{2,}/g,'.').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')" />
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">关闭</el-button>
            </div>
        </el-dialog>

        <!-- 详情 -->
        <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" width="70%" :close-on-click-modal="false">
            <el-form ref="form" :rules="rules" :model="form" label-position="left" label-width="120px">
                <el-form-item label="类型" prop="type">
                    <el-radio-group v-model="form.type" disabled>
                        <el-radio-button :label="1">外卖</el-radio-button>
                        <el-radio-button :label="2">商城</el-radio-button>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="商品原价">
                    <el-input-number v-model="goods_sell_price" :min="0" :precision="2" style="width: 100%;max-width: 220px;" disabled></el-input-number>
                </el-form-item>

                <el-form-item label="折扣数" prop="discount_num">
                    <el-input-number v-model="form.discount_num" :min="0" :max="10" :precision="1" style="width: 100%;max-width: 220px;"></el-input-number>
                </el-form-item>

                <el-form-item label="售价" prop="sell_price">
                    <el-input-number v-model="form.sell_price" :min="0" :precision="2" style="width: 100%;max-width: 220px;" disabled></el-input-number>
                </el-form-item>

                <el-form-item label="权重" prop="weight">
                    <el-input-number v-model="form.weight" :min="0" :precision="0" style="width: 100%;max-width: 220px;"></el-input-number>
                    <div style="line-height:16px;font-size: 12px;color:red;margin-top:10px;">
                        <div>*权重越大，排名越前</div>
                    </div>
                </el-form-item>

                <el-form-item label="状态" prop="is_on">
                    <el-switch v-model="form.is_on" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0">
                    </el-switch>
                </el-form-item>

                <el-form-item label="商品售卖时间" prop="goods_sell_time">
                    <el-table :data="form.goods_sell_time" border fit highlight-current-row style="margin-top: 15px">
                        <el-table-column label="售卖日期" min-width="110" align="center">
                            <template slot-scope="scope">
                                <el-date-picker size="small" v-model="scope.row.sale_date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="选择日期" style="width: 100%;max-width: 150px;"></el-date-picker>
                            </template>
                        </el-table-column>

                        <el-table-column label="总库存量" min-width="110" align="center">
                            <template slot-scope="scope">
                                <el-input-number size="small" v-model="scope.row.total_stock" :min="0" :precision="0" style="width: 100%;max-width: 150px;"></el-input-number>
                            </template>
                        </el-table-column>

                        <el-table-column label="当前库存" min-width="110" align="center">
                            <template slot-scope="scope">
                                {{ scope.row.current_stock }}
                            </template>
                        </el-table-column>

                        <el-table-column label="操作" min-width="110" align="center">
                            <template slot-scope="scope">
                                <el-button size="small" type="primary" @click="addGoodsSaleTime(scope.$index)">添加</el-button>

                                <el-button size="small" type="danger" @click="delGoodsSaleTime(scope.$index)" v-if="form.goods_sell_time.length > 1">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
            </div>
        </el-dialog>
        <!-- 详情 -->
    </div>
</template>

<script>
    import request from "@/utils/request";
    import { mapGetters } from "vuex";

    export default {
        data() {
            return {
                list: null,
                total: null,
                listLoading: true,
                listQuery: {
                    page: 1,
                    limit: 10,
                    school_id: '',
                    keyword: '',
                    canteen_id: '',
                    shop_id: '',
                    category_id: '',
                    is_show: 1,
                    goods_type: '',
                },
                dialogFormVisible: false,
                dialogStatus: '',
                textMap: {
                    update: '修改',
                    create: '新增',
                },
                btnLoading: false,
                form:{
                    id: '',
                    school_id: '',
                    type: '',
                    shop_id: '',
                    goods_id: '',
                    discount_num: '',
                    sell_price: '',
                    weight: 0,
                    is_on: 1,
                    goods_sell_time: [
                        {
                            id: '',
                            daily_special_goods_id: '',
                            sale_date: '',
                            total_stock: 0,
                            current_stock: 0,
                        }
                    ],
                },
                rules: {
                    type: [{ required: true, message: '请选择类型', trigger: 'change' }],
                    discount_num: [{ required: true, message: '折扣数不能为空', trigger: 'change' }],
                    sell_price: [{ required: true, message: '售价不能为空', trigger: 'change' }],
                    weight: [{ required: true, message: '权重不能为空', trigger: 'change' }],
                    is_on: [{ required: true, message: '请选择状态', trigger: 'change' }],
                },
                canteens: [],
                shops: [],
                categories: [],
                // 新增商品类型
                type: null,
                // 商品详情
                dialogVisible: false,
                formInfo: {
                    id: "",
                    school_id: "",
                    shop_id: "",
                    category_id: "",
                    goods_name: "",
                    goods_description: "",
                    goods_thumb: "",
                    sell_price: 0,
                    pack_fee: 0,
                    weight: 0,
                    goods_number: 0,
                    content: "",
                    banners: [],
                    specs: [],
                    attrs: [],
                    is_require: 0,
                },
                goods_sell_price: 0,
            };
        },
        created() {
            this.type = parseInt(this.$route.query.type || 0);
            if (this.type === 1) {
                this.listQuery.goods_type = 'takeawayDailySpecial';
            } else if(this.type === 2) {
                this.listQuery.goods_type = 'shopDailySpecial';
            } else {
                this.$message({
                    type: "warning",
                    message: "参数缺失"
                });
                this.$router.go(-1);
                return;
            }
            this.getList();
            this.getCanteenList();
            this.getShopList();
        },
        computed: {
            ...mapGetters(["schools", "school_id","user"]),
        },
        watch: {
            "listQuery.canteen_id": {
                handler(newValue, oldValue) {
                    if (newValue !== "") {
                        this.listQuery.shop_id = "";
                        this.shops = [];
                        this.listQuery.category_id = "";
                        this.categories = [];
                    }
                    this.getShopList();
                },
                deep: true
            },
            "listQuery.shop_id": {
                handler(newValue, oldValue) {
                    if (newValue === "") {
                        this.listQuery.category_id = "";
                        this.categories = [];
                    } else if (newValue !== oldValue) {
                        this.listQuery.category_id = "";
                        this.getCategoryList();
                    }
                },
                deep: true
            },
            school_id(newVal, oldVal) {
                this.listQuery.school_id = newVal;
                this.getList();
                this.getShopList();
            },
            "form.discount_num": {
                handler(newValue, oldValue) {
                    console.log(newValue)
                    let sell_price = this.goods_sell_price * newValue / 10;
                    this.form.sell_price = sell_price.toFixed(2);
                },
                deep: true
            },
        },
        methods: {
            getList() {
                this.listQuery.school_id = this.school_id;
                this.listLoading = true;
                request({
                    url: "/api/backend/goods/list",
                    method: "get",
                    params: this.listQuery
                }).then(response => {
                    this.list = response.data.data;
                    this.total = response.data.total;
                    this.listLoading = false;
                });
            },
            getCanteenList() {
                request({
                    url: "/api/backend/canteen/list",
                    method: "get",
                    params: {
                        page: 1,
                        limit: 1000000,
                        school_id: this.school_id
                    }
                }).then(response => {
                    this.canteens = response.data.data;
                });
            },
            getShopList() {
                request({
                    url: "/api/backend/shop/list",
                    method: "get",
                    params: {
                        page: 1,
                        limit: 1000000,
                        school_id: this.school_id,
                        shop_type: this.type,
                        canteen_id: this.listQuery.canteen_id,
                    }
                }).then(response => {
                    this.shops = response.data.data;
                });
            },
            getCategoryList() {
                request({
                    url: "/api/backend/category/list",
                    method: "get",
                    params: {
                        page: 1,
                        limit: 1000000,
                        school_id: this.school_id,
                        shop_id: this.listQuery.shop_id
                    }
                }).then(response => {
                    this.categories = response.data.data;
                });
            },
            handleFilter() {
                this.listQuery.page = 1;
                this.getList();
            },
            handleSizeChange(val) {
                this.listQuery.limit = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.listQuery.page = val;
                this.getList();
            },
            resetForm() {
                this.form = {
                    id: '',
                    school_id: '',
                    type: this.type || '',
                    shop_id: '',
                    goods_id: '',
                    discount_num: '',
                    sell_price: '',
                    weight: 0,
                    is_on: 1,
                    goods_sell_time: [
                        {
                            id: '',
                            daily_special_goods_id: '',
                            sale_date: '',
                            total_stock: 0,
                            current_stock: 0,
                        }
                    ],
                }
                this.btnLoading = false
            },
            // 商品详情
            handleInfo(item) {
                this.formInfo = {
                    id: item.id,
                    school_id: item.school_id,
                    shop_id: item.shop_id,
                    category_id: item.category_id,
                    goods_name: item.goods_name,
                    goods_description: item.goods_description,
                    goods_thumb: item.goods_thumb,
                    sell_price: item.sell_price,
                    pack_fee: item.pack_fee,
                    weight: item.weight,
                    goods_number: item.goods_number,
                    content: item.content,
                    banners: item.banners,
                    specs: JSON.parse(JSON.stringify(item.specs)),
                    attrs: JSON.parse(JSON.stringify(item.attrs)),
                    is_require: item.is_require,
                };
                this.dialogVisible = true;
            },
            handleCreate(row) {
                this.resetForm()
                this.goods_sell_price = row.sell_price
                this.form.shop_id = row.shop_id
                this.form.goods_id = row.id
                this.dialogStatus = 'create'
                this.dialogFormVisible = true
                this.$nextTick(() => {
                    this.$refs['form'].clearValidate() //清除校验结果
                })
            },
            saveData() {
                if (!this.school_id) {
                    this.$message({
                        type: "warning",
                        message: "请选择学校"
                    });
                    return;
                } else {
                    this.form.school_id = this.school_id;
                }

                if (!this.form.shop_id) {
                    this.$message({
                        type: "warning",
                        message: "请选择店铺"
                    });
                    return;
                }

                if (!this.form.goods_id) {
                    this.$message({
                        type: "warning",
                        message: "请选择商品"
                    });
                    return;
                }

                if (this.form.goods_sell_time.length === 0) {
                    this.$message({
                        type: "warning",
                        message: "商品售卖时间不能为空",
                    });
                    return;
                }
                for (let index = 0; index < this.form.goods_sell_time.length; index++) {
                    const element = this.form.goods_sell_time[index];

                    if (element.sale_date === '') {
                        this.$message({
                            type: "warning",
                            message: "售卖日期不能为空"
                        });
                        return;
                    }

                    if (element.total_stock === undefined) {
                        this.$message({
                            type: "warning",
                            message: "总库存量不能为空"
                        });
                        return;
                    }
                }

                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.btnLoading = true
                        request({
                            url: '/api/backend/dailySpecialGoods/save',
                            method: 'post',
                            data: this.form
                        }).then(() => {
                            this.btnLoading = false
                            this.dialogFormVisible = false
                            this.$message({
                                type: 'success',
                                message: '操作成功'
                            });
                            this.getList();
                        }).catch(() => {
                            this.btnLoading = false
                        })
                    }
                })
            },
            // 添加商品售卖时间
            addGoodsSaleTime(index) {
                let obj = {
                    id: '',
                    daily_special_goods_id: '',
                    sale_date: '',
                    total_stock: 0,
                    current_stock: 0,
                };

                this.form.goods_sell_time.splice(index+1, 0, obj)
            },
            // 删除商品售卖时间
            delGoodsSaleTime(index) {
                this.form.goods_sell_time.splice(index, 1)
            },
        }
    };
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
    .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 6px;
    }
</style>
